import { useGLTF } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { Suspense } from "react";
import { MqttService } from "../../services/mqtt.service";
import { Box } from "../box/Box";

export const CanvasRenderer = ({
  mqttService,
  reset,
}: {
  mqttService: MqttService;
  reset: boolean;
}) => {
  return (
    <div id="canvas-container" style={{ width: "100%", height: "30vh" }}>
      <Canvas shadows dpr={[1, 2]} camera={{ fov: 1, zoom: 0.8 }}>
        <Suspense fallback={null}>
          <Box mqttService={mqttService} reset={reset}></Box>
        </Suspense>
      </Canvas>
    </div>
  );
};

useGLTF.preload("/scene.gltf");
