import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import {
  AppLayout,
  Box,
  Button,
  Container,
  ContentLayout,
  Grid,
  Header,
  Spinner,
  Toggle,
} from "@cloudscape-design/components";
import { Subscribe } from "@react-rxjs/core";
import { Suspense, useState } from "react";
import "./App.css";
import { CanvasRenderer } from "./components/canvas/Canvas";
import LineGraph from "./components/graphs/LineGraph";
import Metrics from "./components/metrics/Metrics";
import { MqttService } from "./services/mqtt.service";

const Calibration = ({ mqttService }: { mqttService: MqttService }) => {
  return (
    <Button variant="primary" onClick={() => mqttService.calibrate()}>
      Calibrate Gyroscopic Sensors
    </Button>
  );
};

function App({ signOut, user }: { signOut: () => void; user: any }) {
  const mqttService = new MqttService();
  const [reset, setReset] = useState(false);
  const [checked, setChecked] = useState(true);


  return (
    <AppLayout
      headerSelector="#header"
      maxContentWidth={Number.MAX_VALUE}
      toolsHide
      navigationHide
      content={
        <ContentLayout
          header={
            <Header
              variant="h1"
              actions={
                <div>
                  <Calibration mqttService={mqttService}></Calibration>
                  <Button onClick={() => setReset(!reset)}>Reset</Button>
                  <Button onClick={signOut}>Sign out</Button>
                  <Button onClick={() => setChecked(checked => !checked)} variant={checked ? 'primary' : 'normal'}>Diagram</Button>
                </div>
              }
            >
              Pi in a box
            </Header>
          }
        >
          <Suspense
            fallback={
              <Box textAlign="center">
                <Spinner size="large" />
              </Box>
            }
          >
            <Grid
              gridDefinition={[
                ...(checked ? [{ colspan: 12 }] : []),
                { colspan: 6 },
                { colspan: 3 },
                { colspan: 3 },
              ]}
            >
              { checked && <Container
                media={
                    { content: <img src="/architecture.png" style={{}} /> }
                }
              />}
              <Container header={<Header variant="h2">Realtime graphs</Header>}>
                <Subscribe>
                  <LineGraph
                    reset={reset}
                    mqttService={mqttService}
                  ></LineGraph>
                </Subscribe>
              </Container>
              <Container
                disableContentPaddings
                header={<Header variant="h2">3D Representation</Header>}
              >
                <CanvasRenderer
                  reset={reset}
                  mqttService={mqttService}
                ></CanvasRenderer>
              </Container>
              <Container header={<Header variant="h2">Metrics</Header>}>
                <Subscribe>
                  <Metrics mqttService={mqttService} reset={reset}></Metrics>
                </Subscribe>
              </Container>
            </Grid>
          </Suspense>
        </ContentLayout>
      }
      // breadcrumbs={<Breadcrumbs />}
      // navigation={<Navigation />}
      // tools={toolsContent}
      // toolsOpen={toolsOpen}
      // onToolsChange={({ detail }) => setToolsOpen(detail.open)}
      // ariaLabels={appLayoutLabels}
      // notifications={<Notifications />}
    ></AppLayout>
  );
}

export default withAuthenticator(App as any);
