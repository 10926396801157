import { ICredentials } from "@aws-amplify/core";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import awsIot from "aws-iot-device-sdk";
import { Observable, ReplaySubject, Subject, combineLatest, map, tap } from "rxjs";
import {
  IoTSiteWiseClient,
  BatchGetAssetPropertyValueHistoryCommand,
  BatchGetAssetPropertyAggregatesCommand,
  AggregateType,
  BatchGetAssetPropertyValueHistoryResponse,
  BatchGetAssetPropertyAggregatesResponse,
  BatchGetAssetPropertyValueCommand,
  BatchGetAssetPropertyValueResponse,
  Variant,
} from "@aws-sdk/client-iotsitewise";
import moment from "moment";
import { da } from "date-fns/locale";

export interface SensorData {
  acceleration: {
    x: number;
    y: number;
    z: number;
  };
  gyroscope: {
    x: number;
    y: number;
    z: number;
  };
  temperature: number;
  pressure: number;
  altitude: number;
  distance: number;
  deviceId: string;
  timestamp: number;
}

export interface GraphStatus {
  value: number;
  min: number;
  max: number;
  unit: string;
}

export interface GraphData {
  accelerationX: GraphStatus;
  accelerationY: GraphStatus;
  accelerationZ: GraphStatus;
  gyroX: GraphStatus;
  gyroY: GraphStatus;
  gyroZ: GraphStatus;
  temperature: GraphStatus;
  pressure: GraphStatus;
  altitude: GraphStatus;
  distance: GraphStatus;
  deviceId: string;
}

export class MqttService {
  private device!: awsIot.device;
  private group!: string;
  private gyroscope: Subject<any> = new Subject<any>();
  private distance: Subject<any> = new Subject<any>();
  private pressure: Subject<any> = new Subject<any>();
  private innovationData: Subject<any> = new Subject<any>();
  private allData: Subject<SensorData> = new Subject<SensorData>();

  private batchHistory: Subject<BatchGetAssetPropertyValueHistoryResponse> = new ReplaySubject<BatchGetAssetPropertyValueHistoryResponse>(1);
  private aggregate: Subject<BatchGetAssetPropertyAggregatesResponse> = new ReplaySubject<BatchGetAssetPropertyAggregatesResponse>(1);
  private lastDataPoint: Subject<BatchGetAssetPropertyValueResponse> = new ReplaySubject<BatchGetAssetPropertyValueResponse>(1);

  constructor() {
    Promise.all([Auth.currentCredentials(), Auth.currentSession()]).then(
      ([credentials, user]: [ICredentials, CognitoUserSession]) => {
        this.group = user.getAccessToken().payload["cognito:groups"][0];
        this.device = new awsIot.device({
          protocol: "wss",
          clientId: "mqtt-client-" + Math.floor(Math.random() * 100000 + 1), // clientId to register with MQTT broker. Need to be unique per client
          host: "a2f2ys6bi2ktu5-ats.iot.eu-central-1.amazonaws.com",
          port: 443,          
          accessKeyId: credentials.accessKeyId,
          secretKey: credentials.secretAccessKey,
          sessionToken: credentials.sessionToken,
        });

        setInterval(() => {
          const client = new IoTSiteWiseClient({
            credentials,
            region: "eu-central-1",
          });
          client
            .send(
              new BatchGetAssetPropertyValueHistoryCommand({
                entries: [
                  {
                    entryId: "Distance",
                    assetId: "57762cce-edd7-4276-86e8-8526e102748d",
                    propertyId: "ccda3618-f52d-4f94-8076-84e1541c43e1",
                    timeOrdering: "DESCENDING",
                    // startDate: moment().subtract(15, "minutes").toDate()
                  },
                  {
                    entryId: "Temperature",
                    assetId: "57762cce-edd7-4276-86e8-8526e102748d",
                    propertyId: "5093c547-f845-4687-9657-6891e43ac1f5",
                    timeOrdering: "DESCENDING",
                    // startDate: moment().subtract(15, "minutes").toDate()
                  },
                ],
                maxResults: 120,
              })
            )
            .then((data) => {
              this.pressure.next(data);
              this.distance.next(data);
              this.batchHistory.next(data);
            });

          client.send(
            new BatchGetAssetPropertyAggregatesCommand({
              entries: [
                {
                  entryId: "Acceleration_X",
                  assetId: "57762cce-edd7-4276-86e8-8526e102748d",
                  propertyId: "4ec90d6c-1741-48f3-b3c0-b978d8607a9f",
                  startDate: moment().subtract(1, "day").toDate(),
                  endDate: moment().toDate(),
                  resolution: "1d",
                  aggregateTypes: [
                    AggregateType.MINIMUM,
                    AggregateType.MAXIMUM,
                    AggregateType.AVERAGE,
                  ],
                },
                {
                  entryId: "Acceleration_Y",
                  assetId: "57762cce-edd7-4276-86e8-8526e102748d",
                  propertyId: "5093c547-f845-4687-9657-6891e43ac1f5",
                  startDate: moment().subtract(1, "day").toDate(),
                  endDate: moment().toDate(),
                  resolution: "1d",
                  aggregateTypes: [
                    AggregateType.MINIMUM,
                    AggregateType.MAXIMUM,
                    AggregateType.AVERAGE,
                  ],
                },
                {
                  entryId: "Acceleration_Z",
                  assetId: "57762cce-edd7-4276-86e8-8526e102748d",
                  propertyId: "0769ab3e-1816-42e0-840e-30cebdde44ae",
                  startDate: moment().subtract(1, "day").toDate(),
                  endDate: moment().toDate(),
                  resolution: "1d",
                  aggregateTypes: [
                    AggregateType.MINIMUM,
                    AggregateType.MAXIMUM,
                    AggregateType.AVERAGE,
                  ],
                },
                {
                  entryId: "Air_Pressure",
                  assetId: "57762cce-edd7-4276-86e8-8526e102748d",
                  propertyId: "80e43d51-42f7-4e96-8cfc-06bae27356e0",
                  startDate: moment().subtract(1, "day").toDate(),
                  endDate: moment().toDate(),
                  resolution: "1d",
                  aggregateTypes: [
                    AggregateType.MINIMUM,
                    AggregateType.MAXIMUM,
                    AggregateType.AVERAGE,
                  ],
                },
                {
                  entryId: "Altitude",
                  assetId: "57762cce-edd7-4276-86e8-8526e102748d",
                  propertyId: "75bd330d-a61a-4b23-8ca8-bb067a409ea8",
                  startDate: moment().subtract(1, "day").toDate(),
                  endDate: moment().toDate(),
                  resolution: "1d",
                  aggregateTypes: [
                    AggregateType.MINIMUM,
                    AggregateType.MAXIMUM,
                    AggregateType.AVERAGE,
                  ],
                },
                {
                  entryId: "Distance",
                  assetId: "57762cce-edd7-4276-86e8-8526e102748d",
                  propertyId: "ccda3618-f52d-4f94-8076-84e1541c43e1",
                  startDate: moment().subtract(1, "day").toDate(),
                  endDate: moment().toDate(),
                  resolution: "1d",
                  aggregateTypes: [
                    AggregateType.MINIMUM,
                    AggregateType.MAXIMUM,
                    AggregateType.AVERAGE,
                  ],
                },
                {
                  entryId: "Gyroscope_X",
                  assetId: "57762cce-edd7-4276-86e8-8526e102748d",
                  propertyId: "75bd330d-a61a-4b23-8ca8-bb067a409ea8",
                  startDate: moment().subtract(1, "day").toDate(),
                  endDate: moment().toDate(),
                  resolution: "1d",
                  aggregateTypes: [
                    AggregateType.MINIMUM,
                    AggregateType.MAXIMUM,
                    AggregateType.AVERAGE,
                  ],
                },
                {
                  entryId: "Gyroscope_Y",
                  assetId: "57762cce-edd7-4276-86e8-8526e102748d",
                  propertyId: "80e43d51-42f7-4e96-8cfc-06bae27356e0",
                  startDate: moment().subtract(1, "day").toDate(),
                  endDate: moment().toDate(),
                  resolution: "1d",
                  aggregateTypes: [
                    AggregateType.MINIMUM,
                    AggregateType.MAXIMUM,
                    AggregateType.AVERAGE,
                  ],
                },
                {
                  entryId: "Gyroscope_Z",
                  assetId: "57762cce-edd7-4276-86e8-8526e102748d",
                  propertyId: "0769ab3e-1816-42e0-840e-30cebdde44ae",
                  startDate: moment().subtract(1, "day").toDate(),
                  endDate: moment().toDate(),
                  resolution: "1d",
                  aggregateTypes: [
                    AggregateType.MINIMUM,
                    AggregateType.MAXIMUM,
                    AggregateType.AVERAGE,
                  ],
                },
                {
                  entryId: "Temperature",
                  assetId: "57762cce-edd7-4276-86e8-8526e102748d",
                  propertyId: "5093c547-f845-4687-9657-6891e43ac1f5",
                  startDate: moment().subtract(1, "day").toDate(),
                  endDate: moment().toDate(),
                  resolution: "1d",
                  aggregateTypes: [
                    AggregateType.MINIMUM,
                    AggregateType.MAXIMUM,
                    AggregateType.AVERAGE,
                  ],
                },
              ],
            })
          ).then(data => this.aggregate.next(data));


          client.send(
            new BatchGetAssetPropertyValueCommand({
              entries: [
                {
                  entryId: "Acceleration_X",
                  assetId: "57762cce-edd7-4276-86e8-8526e102748d",
                  propertyId: "4ec90d6c-1741-48f3-b3c0-b978d8607a9f",
                },
                {
                  entryId: "Acceleration_Y",
                  assetId: "57762cce-edd7-4276-86e8-8526e102748d",
                  propertyId: "5093c547-f845-4687-9657-6891e43ac1f5",
                },
                {
                  entryId: "Acceleration_Z",
                  assetId: "57762cce-edd7-4276-86e8-8526e102748d",
                  propertyId: "0769ab3e-1816-42e0-840e-30cebdde44ae",
                },
                {
                  entryId: "Air_Pressure",
                  assetId: "57762cce-edd7-4276-86e8-8526e102748d",
                  propertyId: "80e43d51-42f7-4e96-8cfc-06bae27356e0",
                },
                {
                  entryId: "Altitude",
                  assetId: "57762cce-edd7-4276-86e8-8526e102748d",
                  propertyId: "75bd330d-a61a-4b23-8ca8-bb067a409ea8",
                },
                {
                  entryId: "Distance",
                  assetId: "57762cce-edd7-4276-86e8-8526e102748d",
                  propertyId: "ccda3618-f52d-4f94-8076-84e1541c43e1",
                },
                {
                  entryId: "Gyroscope_X",
                  assetId: "57762cce-edd7-4276-86e8-8526e102748d",
                  propertyId: "75bd330d-a61a-4b23-8ca8-bb067a409ea8",
                },
                {
                  entryId: "Gyroscope_Y",
                  assetId: "57762cce-edd7-4276-86e8-8526e102748d",
                  propertyId: "80e43d51-42f7-4e96-8cfc-06bae27356e0",
                },
                {
                  entryId: "Gyroscope_Z",
                  assetId: "57762cce-edd7-4276-86e8-8526e102748d",
                  propertyId: "0769ab3e-1816-42e0-840e-30cebdde44ae",
                },
                {
                  entryId: "Temperature",
                  assetId: "57762cce-edd7-4276-86e8-8526e102748d",
                  propertyId: "5093c547-f845-4687-9657-6891e43ac1f5",
                },
              ],
            })
          ).then(data => this.lastDataPoint.next(data));

          const data: SensorData = {
            acceleration: {
              x: 0,
              y: 0,
              z: 0,
            },
            gyroscope: {
              x: 0,
              y: 0,
              z: 0,
            },
            temperature: 0,
            pressure: 0,
            altitude: 0,
            distance: 0,
            deviceId: "123",
            timestamp: 0,
          };

          // this.pressure.next(data);
          this.gyroscope.next(data);
          this.allData.next(data);
        }, 1000);
      }
    );
  }

  calibrate(): void {
    this.device.publish(
      `cmd/pibox/box/${this.group}/calibrate`,
      JSON.stringify({})
    );
  }

  private getGraphStatus(aggregate: BatchGetAssetPropertyAggregatesResponse, dataPoint: BatchGetAssetPropertyValueResponse, entryId: string, variant: keyof Variant, unit: string): GraphStatus {
    const aggregateValue = aggregate.successEntries?.find(item => item.entryId === entryId)?.aggregatedValues?.at(0)

    return {
      value: this.getDataPointValue(dataPoint, entryId, variant),
      min: aggregateValue?.value?.minimum || 0,
      max: aggregateValue?.value?.maximum || 0,
      unit: unit
    }
  }

  private getDataPointValue(dataPoint: BatchGetAssetPropertyValueResponse, entryId: string, variant: keyof Variant): number {
    const latestValue = dataPoint.successEntries?.find(item => item.entryId === entryId)?.assetPropertyValue?.value
    return (latestValue ? latestValue[variant] : 0) as number
  }

  get gyroscope$(): Observable<Pick<SensorData, "gyroscope">> {
    return this.lastDataPoint$.pipe(
      map(dataPoint => ({
        gyroscope: {
          x: this.getDataPointValue(dataPoint, "Gyroscope_X", "doubleValue"),
          y: this.getDataPointValue(dataPoint, "Gyroscope_Y", "doubleValue"),
          z: this.getDataPointValue(dataPoint, "Gyroscope_Z", "doubleValue"),
        }
      }))
    );
  }

  get batchHistory$(): Observable<BatchGetAssetPropertyValueHistoryResponse> {
    return this.batchHistory.asObservable();
  }

  get aggregate$(): Observable<BatchGetAssetPropertyAggregatesResponse> {
    return this.aggregate.asObservable();
  }

  get lastDataPoint$(): Observable<BatchGetAssetPropertyValueResponse> {
    return this.lastDataPoint.asObservable();
  }

  get graphData$(): Observable<GraphData> {
    return combineLatest([this.aggregate, this.lastDataPoint]).pipe(
      map(([aggregate, dataPoint]: [BatchGetAssetPropertyAggregatesResponse, BatchGetAssetPropertyValueResponse]) => {
        return {
          accelerationX: this.getGraphStatus(aggregate, dataPoint, "Acceleration_X", "doubleValue", "g"),
          accelerationY: this.getGraphStatus(aggregate, dataPoint, "Acceleration_Y", "doubleValue", "g"),
          accelerationZ: this.getGraphStatus(aggregate, dataPoint, "Acceleration_Z", "doubleValue", "g"),
          gyroX: this.getGraphStatus(aggregate, dataPoint, "Gyroscope_X", "doubleValue", "°/sec"),
          gyroY: this.getGraphStatus(aggregate, dataPoint, "Gyroscope_Y", "doubleValue", "°/sec"),
          gyroZ: this.getGraphStatus(aggregate, dataPoint, "Gyroscope_Z", "doubleValue", "°/sec"),
          temperature: this.getGraphStatus(aggregate, dataPoint, "Temperature", "doubleValue", "°C"),
          pressure: this.getGraphStatus(aggregate, dataPoint, "Air_Pressure", "doubleValue", "hPa"),
          altitude: this.getGraphStatus(aggregate, dataPoint, "Altitude", "doubleValue", "m"),
          distance: this.getGraphStatus(aggregate, dataPoint, "Distance", "integerValue", "mm"),
          deviceId: ""
        }
      }),
    )
  }

}
