import { Stage, useGLTF, useTexture } from "@react-three/drei";
import { useEffect, useRef } from "react";
import * as THREE from "three";
import { Group } from "three";
import { MqttService } from "../../services/mqtt.service";

export const Box = ({
  mqttService,
  reset,
}: {
  mqttService: MqttService;
  reset: boolean;
}) => {
  const pivotRef = useRef<Group>();

  useEffect(() => {
    if (!pivotRef.current) {
      return;
    }

    const subscription = mqttService.gyroscope$.subscribe((data) => {
      const xAdjust = Math.trunc(data.gyroscope.x * 10) / 10; /*+ 0.11;*/
      const yAdjust = Math.trunc(data.gyroscope.y * 10) / 10; /*+ 0.04;*/
      const zAdjust = Math.trunc(data.gyroscope.z * 10) / 10; /*+ 0.02;*/

      const scaleFactor = 0.4;
      pivotRef.current!.rotateX(-xAdjust * scaleFactor);
      pivotRef.current!.rotateY(zAdjust * scaleFactor);
      pivotRef.current!.rotateZ(yAdjust * scaleFactor);
    });

    return () => subscription.unsubscribe();
  }, [pivotRef]);

  useEffect(() => {
    if (!pivotRef.current) {
      return;
    }

    pivotRef.current.rotation.x = 0;
    pivotRef.current.rotation.y = 0;
    pivotRef.current.rotation.z = 0;
  }, [reset]);

  const gltf = useGLTF("/scene.gltf") as any;
  const texture = useTexture(
    { map: "/textures/Caja_de_amazon_baseColor.jpeg", roughnessMap: "/textures/Caja_de_amazon_metallicRoughness.png", normalMap: "/textures/Caja_de_amazon_normal.png" },
    (texture) => {
      if (!Array.isArray(texture)) {
        texture = [texture];
      }

      texture.forEach(t => {
        t.wrapS = t.wrapT = THREE.MirroredRepeatWrapping;
      })

    }
  );


  return (
    <Stage preset="rembrandt" intensity={1} environment="city">
      <primitive object={gltf.scene} ref={pivotRef as any} />
      {/* <group dispose={null} {...nodes.Sketchfab_Scene}>
        <mesh
          castShadow
          ref={pivotRef as any}
          receiveShadow
          {...nodes.CajaAmazon_LP_MAXR_18KCaja_RES15_PruebaUV_1_Caja_de_amazon_0}
        >
          <meshStandardMaterial {...texture} />
        </mesh>
      </group> */}
    </Stage>
  );
};

useGLTF.preload("/scene.gltf");
