import { LineChart, Spinner } from "@cloudscape-design/components";
import { state, useStateObservable } from "@react-rxjs/core";
import { filter, map, scan, tap } from "rxjs";
import { MqttService } from "../../services/mqtt.service";
import moment from "moment"

const temperature$ = state(
  (mqttService: MqttService) =>
    mqttService.batchHistory$.pipe(
      map((data) => data.successEntries?.at(1)?.assetPropertyValueHistory?.map((item: any) => ({x: moment.unix(item.timestamp.timeInSeconds).toDate(), y: item.value.doubleValue})) || []),
    ),
  []
);

const distance$ = state(
  (mqttService: MqttService) =>
    mqttService.batchHistory$.pipe(
      map((data) => data.successEntries?.at(0)?.assetPropertyValueHistory?.map((item: any) => ({x: moment.unix(item.timestamp.timeInSeconds).toDate(), y: item.value.integerValue / 100})) || [])
    ),
  []
);

const LineGraph = (props: { mqttService: MqttService; reset: boolean }) => {
  const height = 320;

  const temperatureSeries = useStateObservable(temperature$(props.mqttService));
  const distanceSeries = useStateObservable(distance$(props.mqttService));

  // if (!temperatureSeries || !distanceSeries || !temperatureSeries?.at(0)?.data?.length || !distanceSeries?.at(0)?.data?.length) {
  //   return <Spinner size="big" />;
  // }

  return (
    <>
      <LineChart
        series={[{
          title: "Temperature",
          type: "line" as "line",
          data: temperatureSeries,
        }]}
        // yDomain={[10, 30]}
        hideFilter
        height={height}
        hideLegend
        xScaleType="time"
        xTitle="Time (UTC)"
        i18nStrings={{
          xTickFormatter: (e) =>
            (e as Date)
              .toLocaleDateString("en-US", {
                month: "short",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
                hour12: !1,
              })
              .split(",")
              .join("\n"),
        }}
        yTitle="Temperature (°C)"
      ></LineChart>
      <LineChart
        series={[{
          title: "Distance",
          type: "line" as "line",
          data: distanceSeries,
        }]}
        // yDomain={[0, 50]}
        hideFilter
        height={height}
        hideLegend
        xScaleType="time"
        xTitle="Time (UTC)"
        i18nStrings={{
          xTickFormatter: (e) =>
            (e as Date)
              .toLocaleDateString("en-US", {
                month: "short",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
                hour12: !1,
              })
              .split(",")
              .join("\n"),
        }}
        yTitle="Distance (cm)"
      ></LineChart>
    </>
  );
};
export default LineGraph;
