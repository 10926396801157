import { state, useStateObservable } from "@react-rxjs/core";
import { Box, Meter, Stack, Text } from "grommet";
import { scan, tap } from "rxjs";
import { GraphData, GraphStatus, MqttService, SensorData } from "../../services/mqtt.service";

const initial: GraphData = {
  deviceId: "",
  accelerationX: {
    min: Infinity,
    max: -Infinity,
    value: 0,
    unit: "g",
  },
  accelerationY: {
    min: Infinity,
    max: -Infinity,
    value: 0,
    unit: "g",
  },
  accelerationZ: {
    min: Infinity,
    max: -Infinity,
    value: 0,
    unit: "g",
  },
  gyroX: {
    min: Infinity,
    max: -Infinity,
    value: 0,
    unit: "°/sec",
  },
  gyroY: {
    min: Infinity,
    max: -Infinity,
    value: 0,
    unit: "°/sec",
  },
  gyroZ: {
    min: Infinity,
    max: -Infinity,
    value: 0,
    unit: "°/sec",
  },
  temperature: {
    min: Infinity,
    max: -Infinity,
    value: 0,
    unit: "°C",
  },
  pressure: {
    min: Infinity,
    max: -Infinity,
    value: 0,
    unit: "hPa",
  },
  altitude: {
    min: Infinity,
    max: -Infinity,
    value: 0,
    unit: "m",
  },
  distance: {
    min: Infinity,
    max: -Infinity,
    value: 0,
    unit: "cm",
  },
};

const data$ = state((mqttService: MqttService) =>
  mqttService.graphData$.pipe(
  ), initial
);

type Size =
  | "xsmall"
  | "small"
  | "medium"
  | "large"
  | "xlarge"
  | "xxlarge"
  | "2xl"
  | "3xl"
  | "4xl"
  | "5xl"
  | "6xl"
  | string;

interface MetricVariant {
  titleSize: Size;
  meterSize: Size;
  meterThickness: Size;
  valueSize: Size;
  unitSize: Size;
}

const regular: MetricVariant = {
  titleSize: "large",
  meterSize: "small",
  meterThickness: "medium",
  valueSize: "large",
  unitSize: "medium",
};

const small: MetricVariant = {
  titleSize: "medium",
  meterSize: "xsmall",
  meterThickness: "small",
  valueSize: "small",
  unitSize: "xsmall",
};

const toPercentage = (data: GraphStatus): number =>
  ((data.value - data.min) / (data.max - data.min)) * 100;

const Metric = ({
  value,
  title,
  variant,
}: {
  value: GraphStatus;
  title: string;
  variant: MetricVariant;
}) => (
  <Box
    align="center"
    pad={{ bottom: "small" }}
    margin={{ top: "small", right: "small" }}
  >
    <Text size={variant.titleSize} weight="bold">
      {title}
    </Text>
    <Stack anchor="center">
      <Meter
        value={toPercentage(value)}
        max={100}
        type="circle"
        aria-label="meter"
        color="brand"
        size={variant.meterSize}
        thickness={variant.meterThickness}
        background="light-2"
      />
      <Box direction="row" align="center" pad="medium">
        <Text size={variant.valueSize} weight="bold">
          {value.value.toFixed(2)}
        </Text>
        <Text size={variant.unitSize}>{value.unit}</Text>
      </Box>
    </Stack>
  </Box>
);

const Metrics = (props: { mqttService: MqttService; reset: boolean }) => {
  const data: GraphData = useStateObservable(data$(props.mqttService));
  return (
    <div>
      <Metric title="Distance" value={data.distance} variant={small}></Metric>
      <Box align="center" margin={{ top: "small" }}>
        <Box direction="row">
          <Metric
            title="Temperature"
            value={data.temperature}
            variant={small}
          ></Metric>
          <Metric
            title="Pressure"
            value={data.pressure}
            variant={small}
          ></Metric>
          <Metric
            title="Altitude"
            value={data.altitude}
            variant={small}
          ></Metric>
        </Box>
      </Box>
      <Box align="center" margin={{ top: "small" }}>
        <Text size="large" weight="bold">
          Acceleration
        </Text>
        <Box direction="row">
          <Metric title="X" value={data.accelerationX} variant={small}></Metric>
          <Metric title="Y" value={data.accelerationY} variant={small}></Metric>
          <Metric title="Z" value={data.accelerationZ} variant={small}></Metric>
        </Box>
      </Box>
      <Box align="center" margin={{ top: "small" }}>
        <Text size="large" weight="bold">
          Gyroscope
        </Text>
        <Box direction="row">
          <Metric title="X" value={data.gyroX} variant={small}></Metric>
          <Metric title="Y" value={data.gyroY} variant={small}></Metric>
          <Metric title="Z" value={data.gyroZ} variant={small}></Metric>
        </Box>
      </Box>
    </div>
  );
};
export default Metrics;
